<template>
  <section class="section">
    <h4 class="title has-text-centered is-4">Tools</h4>

    <b-loading :is-full-page="false" v-model="loadingForm"></b-loading>

    <hr />

    <h6 class="title is-6">Sync Prod DB</h6>
    <div class="columns">
      <div class="column is-10">
        This will wipe out the entire local database and import the latest daily backup from PROD. Think twice before you do that!
      </div>
      <div class="column">
        <b-button type="is-primary" native-type="submit" size="is-small" @click="handleSync">SYNC</b-button>
      </div>
    </div>

    <hr />
  </section>
</template>

<script>
import { Tools } from '@/services/api';

export default {
  name: 'Tools',
  data() {
    return {
      errors: [],
      loadingForm: false,
    };
  },
  methods: {
    handleSync() {
      this.$buefy.dialog.confirm({
        title: 'Import PROD DB?',
        message: 'Are you sure you want to <b>DROP</b> the current database and import the latest PROD backup?<br /><br />This action cannot be undone!<br /><br />Also it might take more than a minute, so be patient!',
        confirmText: 'DROP and IMPORT',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.loadingForm = true;

          Tools.syncProdDatabase()
            .then((data) => {
              if (data.error) {
                this.showError();
              } else {
                this.showSuccess();
              }
            })
            .catch(() => {
              this.showError();
            })
            .finally(() => {
              this.loadingForm = false;
            });
        },
      });
    },
    showError() {
      this.$buefy.dialog.alert({
        title: 'Ouch..!',
        message: 'That HURTS! Check the database and import manually!',
        type: 'is-danger',
        hasIcon: true,
        icon: 'times-circle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true,
      });
    },
    showSuccess() {
      this.$buefy.dialog.alert({
        title: 'Nice!',
        message: 'Awesome! Database was imported successfully!',
        type: 'is-success',
        hasIcon: true,
        icon: 'check',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true,
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
